import { Elements, ElementsType, Element, ElementsStyle, CreateTokenResponse, CardChangeMessage } from './types';
import { generateID } from './helpers';
import messages from './messages';
import config from './config';

const E = (publicKey: string): Elements => {
  let mountedFrame: null | HTMLIFrameElement = null;
  let id: null | string = null;
  let elementListener: ((e: MessageEvent) => void) | null = null;

  let eventHandlers: { [event: string]: ((e: any) => void) | null } = {
    change: null,
  };

  return {
    create(type: ElementsType, styles?: ElementsStyle): Element {
      console.log(publicKey, type, styles);
      return {
        mount(domSelector: string) {
          console.log(`Mounting element to ${domSelector}`);
          const container = document.getElementById(domSelector);
          const iframe = document.createElement('iframe');
          iframe.width = '100%';
          iframe.height = '44px';
          iframe.setAttribute('id', 'magpie-elements');
          iframe.setAttribute('style', 'border:none');
          iframe.setAttribute('src', config.appUrl);
          iframe.setAttribute('name', 'magpie-elements-' + Date.now());
          console.log(container);
          if (container) {
            container.appendChild(iframe);
            mountedFrame = iframe;
            id = generateID();
            console.log(mountedFrame);

            const params = {
              type: messages.INITIAL,
              id,
              publicKey,
            };

            // TODO: PING iframe if ready before you pass the params

            // Then, send the params for initialization
            setTimeout(() => {
              console.log('SEND MESSAGE');
              iframe.contentWindow?.postMessage(JSON.stringify(params), config.appUrl);
            }, 1000);

            // once mounted, add event listeners for 'on' events
            elementListener = (e: MessageEvent) => {
              console.log(e.data);
              let data = null;
              try {
                data = JSON.parse(e.data);
              } catch (e) {
                return;
              }

              if (data.id === id) {
                switch (data.type) {
                  case messages.EVENT_CHANGE:
                    if (eventHandlers.change) {
                      eventHandlers.change(data.data);
                    }
                    break;
                  case messages.EVENT_COMPLETE:
                    break;
                }
              }
            };
            window.addEventListener('message', elementListener);
          }
        },

        unmount() {
          if (elementListener) {
            window.removeEventListener('message', elementListener);
          }
        },

        on(type: string, cb: () => void) {
          switch (type) {
            case 'change':
              eventHandlers.change = cb;
              break;
            default:
              throw new Error("Can't recognize type");
          }
        },
        createToken() {
          if (mountedFrame) {
            const params = {
              type: messages.CREATE_CARD_TOKEN,
              id,
            };
            console.log('CREATE Card Token Request', params);

            mountedFrame?.contentWindow?.postMessage(JSON.stringify(params), config.appUrl);

            return new Promise((resolve, reject) => {
              const listener = (e: MessageEvent) => {
                console.log('Bootstrap', e.data);

                let data: any = null;
                try {
                  data = JSON.parse(e.data);
                } catch (e) {
                  return;
                }

                if (data.id === id) {
                  switch (data.type) {
                    case messages.SUCCESS_CARD_TOKEN_CREATE:
                      window.removeEventListener('message', listener, false);
                      const result: CreateTokenResponse = {
                        token: data.data,
                      };
                      resolve(result);
                      break;
                    case messages.ERROR_CARD_TOKEN_CREATE:
                      window.removeEventListener('message', listener, false);
                      const message: CardChangeMessage = {
                        error: data.data,
                        complete: false,
                      };
                      // eventHandlers.change(message);
                      reject(message);
                      break;
                  }
                }
              };
              window.addEventListener('message', listener);
            });

            // return promise;
            // return promise.then((response: CreateTokenResponse) => {
            //   console.log('INSIDE', response);
            //   return Promise.resolve<CreateTokenResponse>(response);
            // });jkk
          }
          throw new Error('Frame is not yet mounted.');
        },
      };
    },
  };
};

export default E;
