import { Magpie, Element } from './types';
import Elements from './elements';

const Magpie = (publicKey: string): Magpie => {
  return {
    elements: () => {
      const elements = Elements(publicKey);
      return elements;
    },
    createToken: (element: Element) => {
      return element.createToken();
    },
  };
};

window.Magpie = Magpie;
